#intro{
  display: flex;
}

#image-div{
  width: 25%;
}

@media screen and (max-width: 1137px){
  #image-div{
    display: none;
  }
  #intro-text-div{
    flex-grow: 1;
  }
}

#image-div img{
  width: 100%;
  height: 100%;
}

#intro-text-div{
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bg);
  padding: 2rem;
}

#intro-text-div h1{
  /* text-align: center; */
  font-size: 3rem;
  /* margin-bottom: .1rem; */
}

#intro-text-div h2{
  /* text-align: center; */
  margin-bottom: .6rem;
}

#intro-text-div p{
  /* text-align: center; */
  margin-bottom: .3rem;
}

b{
  font-size: 1.1rem;
}

i{
  text-align: justify;
}