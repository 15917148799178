#project-box{
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.project-div{
    display: flex;
    margin-top: 2rem;
    background-color: var(--bg);
}

@media screen and (max-width: 1137px){
    .project-div{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        background-color: var(--bg);
        align-items: center;
    }
    .project-description-div{
        flex-grow: 1;
    }
}

.project-description-div{
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-name-h2 a{
    text-decoration: none;
}

.project-description-p{
    margin-top: 1rem;
    color: var(--font-color);
}

.project-image-div{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.project-image-div img{
    width: 100%;
}

.project-buttons-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: .5rem;
}

.project-buttons-div a{
    display: inline-block;
    width: 48%;
    background-color: whitesmoke;
    text-align: center;
    text-decoration: none;
    padding: .5rem;
    color: black;
}

.project-buttons-div a:hover{
    background-color: var(--hover);
}

#projects-title-div{
    background-color: var(--bg);
    margin-top: 2rem;
    text-align: center;
    padding: 2rem;
    font-size: 1.5rem;
  }