nav{
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--bg);
    top: 0;
}

nav h1{
    padding: 1rem;
    width: 12rem;
    text-align: center;
    font-size: 1.8rem;
}

nav h1:hover{
    background-color: var(--hover);
}

nav a {
    text-decoration: none;
}

#nav-a-resume{
    margin-left: auto;
}

#nav-a-resume a{
    color: var(--font-color);
}